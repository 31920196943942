<template>
  <v-col>
    <v-autocomplete
      v-model="value"
      :items="transportadorasSelecionadas"
      :search-input.sync="search"
      :label="label"
      :prepend-icon="icon"
      no-filter
      item-text="trade_name"
      item-value="transportadora_id"
      @input="$emit('input', $event)"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.trade_name"></v-list-item-title>
          <v-list-item-subtitle v-text="`CNPJ ${item.cnpj} - ${item.city} - ${item.district}`"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TransportadorasApi from '@/services/tenants/transportadoras.js'
import { debounce } from 'lodash'

export default {
  name: 'FaturamentoListFiltroModalItemInputTransportadora',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    mask: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    search: '',
    transportadora: '',
    transportadorasSelecionadas: [],
    params: {
      inicias: {
        value: '',
      },
    },
    debouncedSearch: null,
  }),
  methods: {
    ...mapActions('transportadoras', [
      'getTransportadoras',
      'getTransportadoraEspecifica',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    async performSearch(search) {
      if (search.length >= 2) {
        try {
          const transportadora = await TransportadorasApi.getTransportadoraEspecifica(
            this.empresaAtual,
            { pesquisa: search }
          )
          this.applyFilter(transportadora, search)
        } catch (e) {
          console.error('Erro na busca de transportadoras:', e)
          if (e.response && e.response.status === 404) {
            this.errorMessage('Página não encontrada')
          }
        }
      } else {
        this.transportadorasSelecionadas = []
      }
    },

    applyFilter(transportadoras, searchTerm) {
      this.transportadorasSelecionadas = this.filterTransportadoras(transportadoras, searchTerm)
    },

    filterTransportadoras(transportadoras, searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase()
      return transportadoras.filter(t => {
        const lowerTradeName = t.trade_name.toLowerCase()
        if (lowerTradeName === lowerSearchTerm) {
          return true
        }
        if (searchTerm.length <= 3) {
          return lowerTradeName.includes(lowerSearchTerm)
        }
        return lowerTradeName.split(' ').some(word => word.startsWith(lowerSearchTerm))
      })
    },
  },
  computed: {
    ...mapState('transportadoras', ['params']),
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 300)
  },
  watch: {
    search(newSearch) {
      this.applyFilter(this.transportadorasSelecionadas, newSearch)
      this.debouncedSearch(newSearch)
    },
  },
}
</script>